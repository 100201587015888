import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService, NbTokenService } from '@nebular/auth';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    private authService: NbAuthService,
    private tokenService: NbTokenService,
    private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getToken().pipe(
      mergeMap(token =>{
        if (token) {
          const tokenizedReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
          return next.handle(tokenizedReq);
        }
        return next.handle(req);
      }),
      catchError((err: any) => {
        if (err.status == 401) {
          this.tokenService.clear().subscribe(res => {
            this.router.navigateByUrl('/auth/login');
          });
        }
        return throwError(err);
      })
    );
  }
}
