import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class UserServices {
  constructor(private http: HttpClient) {}
  StoredUserEmail: any;
  GetUserLists(val: any) {
    let params = new HttpParams();
    if (val.page != "") {
      params = params.append("page", val.page);
    }
    if (val.pageSize!= "") {
      params = params.append("pageSize", val.pageSize);
    }
    if (val.sort!= "") {
      params = params.append("sort", val.sort);
    }
    if (val.sortBy!= "") {
      params = params.append("sortBy", val.sortBy);
    }
    if (val.search!= "") {
      params = params.append("search", val.search);
    }
    return this.http.get<any>(environment.devapiurl + "/users", {
      params: params,
    });
  }
  CreateNewUser(val:any){
    const headers= new HttpHeaders()
    .set('Accept', "application/json")
    return this.http.post<any>(environment.devapiurl+'/users',val,{'headers':headers});
  }
  UpdateUserDetails(val:any,userid){
    const headers= new HttpHeaders()
    .set('Accept', "application/json")
    let params = new HttpParams();
    params = params.append("id", userid);
    const options = { params: params, headers: headers };
    return this.http.put<any>(environment.devapiurl+'/users',val,options);
  }
  DeletetheUser(userid){
    const headers= new HttpHeaders()
    .set('Accept', "application/json")
    let params = new HttpParams();
    params = params.append("id", userid);
    const options = { params: params, headers: headers };
    return this.http.delete<any>(environment.devapiurl+'/users',options);
  }
  ResetPassword(val){
    return this.http.post<any>(environment.devapiurl+'/auth/reset',val);
  }
}
